import React from "react";
import {
	Grid,
	GridItem,
	Title,
	PageSection,
	Text,
	TextVariants,
	Button,
} from "@patternfly/react-core";
import labImage from "../assets/img12.png";
export default function Lab() {
	const goToBlog = () => {
		window.open("https://essays.ground-central.com.au/", "_blank");
	};
	return (
		<PageSection
			style={{
				backgroundColor: "#EEEEEE",
			}}
		>
			<Grid
				hasGutter
				style={{
					maxWidth: "1440px",
					margin: "0 auto",
					width: "100%",
				}}
			>
				<GridItem span={12} sm={12} md={6} order={{ default: 2, md: 1, sm: 2 }}>
					<Title headingLevel="h3" size="2xl" style={{ marginBottom: "40px" }}>
						GC-LAB
					</Title>
					<Text component={TextVariants.p} style={{ marginBottom: "40px" }}>
						Ground Central provides a platform for collaborative tool
						development. The tools at Ground Central were created from
						experimentation of members. Backed by machine learning capabilities.
					</Text>
					<Button variant="primary" size="sm" onClick={goToBlog}>
						Read more
					</Button>{" "}
				</GridItem>
				<GridItem
					span={12}
					sm={12}
					md={6}
					order={{ default: 1, md: 2, sm: 1 }}
					className="image-alignment"
				>
					<img alt="lab info" src={labImage} style={{ maxWidth: "500px" }} />
				</GridItem>
			</Grid>
		</PageSection>
	);
}
