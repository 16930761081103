import React from "react";
import {
	PageSection,
	Title,
	Button,
	Bullseye,
	Stack,
	StackItem,
} from "@patternfly/react-core";

const BookDemo = () => {
	const handleClick = () => {
		window.location.href = "mailto:admin@ground-central.com.au";
	};
	return (
		<PageSection style={{ backgroundColor: "#003566", color: "#FFF" }}>
			<Bullseye>
				<Stack hasGutter>
					<StackItem>
						<Title headingLevel="h1" size="2xl">
							See It For Yourself
						</Title>
					</StackItem>
					<StackItem>
						<Bullseye>
							<Button variant="primary" onClick={handleClick}>
								Book a Demo
							</Button>
						</Bullseye>
					</StackItem>
				</Stack>
			</Bullseye>
		</PageSection>
	);
};

export default BookDemo;
