import React from "react";
import {
    Grid,
    GridItem,
    Title,
    PageSection,
    Text,
    TextVariants,
    Button,
} from "@patternfly/react-core";
import mackbook from "../assets/Mackbook.png";
export default function MachineLearning() {
    return (
        <PageSection>
            <Grid
                hasGutter
                style={{
                    maxWidth: "1440px",
                    margin: "0 auto",
                    width: "100%",
                }}
            >
                <GridItem
                    span={12}
                    sm={12}
                    md={6}
                    order={{ default: 2, md: 1, sm: 2 }}
                    // style={{
                    //     textAlign: "left",
                    //     display: "flex",
                    //     justifyContent: "flex-end",
                    // }}
                    className="custom-grid-item"
                >
                    <div className="custom-grid-content">
                        <Title
                            headingLevel="h3"
                            size="2xl"
                            style={{ marginBottom: "12px" }}
                        >
                            Developing Machine Learning Tools
                        </Title>
                        <Text
                            component={TextVariants.p}
                            style={{ marginBottom: "12px", color: "#0D6EFD" }}
                        >
                            Specialised Geotechnical AI and Machine Learning
                            consultant offering bespoke solutions.
                        </Text>
                        <Text
                            component={TextVariants.p}
                            style={{ marginBottom: "24px" }}
                        >
                            Helping you digitalise and accelerate Machine
                            Learning Techniques for your business.
                        </Text>
                        <Text
                            component={TextVariants.p}
                            style={{ marginBottom: "24px" }}
                        >
                            Helping you integrate existing tools.
                        </Text>
                        <Button variant="primary" size="sm">
                            Learn more
                        </Button>{" "}
                    </div>
                </GridItem>
                <GridItem
                    span={12}
                    sm={12}
                    md={6}
                    order={{ default: 1, md: 2, sm: 1 }}
                >
                    <img
                        className=""
                        alt=""
                        src={mackbook}
                        style={{ maxWidth: "450px", width: "100%" }}
                    />
                </GridItem>
            </Grid>
        </PageSection>
    );
}
