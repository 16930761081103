import React from "react";
import { Flex, FlexItem, Brand } from "@patternfly/react-core";
import { Text, TextVariants, Title } from "@patternfly/react-core";
import ats from "../assets/ATS.jpg";
import engineersAustralia from "../assets/EngineersAustralia.jpg";
import ice2 from "../assets/ICE2.jpg";
import isrm from "../assets/ISRM.png";
import issmge2 from "../assets/ISSMGE2.png";
import ita from "../assets/ITA.jpg";

const logos = [ats, engineersAustralia, ice2, isrm, issmge2, ita];

export default function Brands() {
	return (
		<div
			style={{
				backgroundColor: "#EEEEEE",
			}}
		>
			<div
				style={{
					width: "100%",
					textAlign: "center",
					padding: "80px",
					maxWidth: "1440px",
					margin: "0 auto",
				}}
			>
				<Text
					component={TextVariants.h6}
					style={{
						fontSize: "12px",
						color: "#0D6EFD",
					}}
				>
					Brands
				</Text>
				<Title
					headingLevel="h3"
					size="2xl"
					style={{ marginBottom: "20px", marginTop: "20px" }}
				>
					We work with members of the world’s international societies
				</Title>

				<Flex
					columnGap={{ default: "columnGap2xl" }}
					justifyContent={{ default: "justifyContentCenter" }}
				>
					{logos.map((logo, index) => (
						<FlexItem key={index} style={{ padding: "20px" }}>
							<Brand
								src={logo}
								alt={`Brand logo ${index + 1}`}
								heights={{ default: "80px" }}
							/>
						</FlexItem>
					))}
				</Flex>
			</div>
		</div>
	);
}
