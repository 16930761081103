import "@patternfly/react-core/dist/styles/base.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.jsx";
import AboutUs from "./pages/AboutUs";
import Apps from "./pages/Apps";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/apps" element={<Apps />} />
            </Routes>
        </BrowserRouter>
    );
}
