import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Grid,
    GridItem,
    PageSection,
    Title,
    Form,
    FormGroup,
    TextContent,
    Text,
    FileUpload,
} from "@patternfly/react-core";
import { Link } from "react-router-dom";
import { Page, SkipToContent } from "@patternfly/react-core";

import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";

export default function Apps() {
    const mainContainerId = "main-content";
    const pageSkipToContent = (
        <SkipToContent href={`#${mainContainerId}`}>
            Skip to content
        </SkipToContent>
    );

    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = (value, filename) => {
        setFile(value);
        setFilename(filename);
    };
    const handleClear = () => {
        setFile(null);
        setFilename("");
    };

    const handleUpload = () => {
        if (file) {
            setIsLoading(true);
            // Simulate file upload
            setTimeout(() => {
                console.log("File uploaded:", file);
                setIsLoading(false);
                setFile(null);
                setFilename("");
            }, 2000);
        }
    };
    return (
        <Page
            header={<Header />}
            sidebar={<SideBar />}
            isManagedSidebar
            skipToContent={pageSkipToContent}
            mainContainerId={mainContainerId}
            style={{ backgroundColor: "#373A40" }}
        >
            <PageSection>
                <div
                    style={{
                        maxWidth: "1440px",
                        margin: "0 auto",
                        width: "100%",
                        padding: "48px 24px",
                    }}
                >
                    <Title headingLevel="h1">App Store</Title>
                    <TextContent className="pf-u-mb-lg">
                        <Text component="p">
                            Upload your application package to add it to the App
                            Store.
                        </Text>
                    </TextContent>
                    <Form className="pf-u-mb-xl">
                        <FormGroup
                            label=""
                            fieldId="file-upload"
                            className="pf-u-mb-md"
                        >
                            <FileUpload
                                id="file-upload"
                                value={file}
                                filename={filename}
                                onFileInputChange={handleFileChange}
                                onDataChange={handleFileChange}
                                onClearClick={handleClear}
                                dropzoneProps={{
                                    accept: {
                                        "image/png": [".png"],
                                        "text/html": [".html", ".htm"],
                                        "application/zip": [".zip"],
                                        "application/gzip": [".tar.gz"],
                                    },
                                    onDropAccepted: (files) =>
                                        handleFileChange(
                                            files[0],
                                            files[0].name
                                        ),
                                }}
                            />
                        </FormGroup>
                        <Button
                            variant="primary"
                            onClick={handleUpload}
                            isLoading={isLoading}
                            isDisabled={!file}
                        >
                            {isLoading ? "Uploading..." : "Upload"}
                        </Button>
                    </Form>

                    <Grid hasGutter style={{ marginTop: "96px" }}>
                        {[...Array(10)].map((_, index, link) => (
                            <GridItem key={index} sm={12} md={6} lg={3}>
                                <Link
                                    to={link}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Card>
                                        <CardHeader>
                                            <Title headingLevel="h2" size="lg">
                                                App {index + 1}
                                            </Title>
                                        </CardHeader>
                                        <CardBody>
                                            Description of the app {index + 1}.
                                        </CardBody>
                                    </Card>
                                </Link>
                            </GridItem>
                        ))}
                    </Grid>
                </div>
            </PageSection>
            <Footer />
        </Page>
    );
}
