import React from "react";
import { Page, SkipToContent } from "@patternfly/react-core";

import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Lab from "../components/Lab";
import Blog from "../components/Blog.jsx";
import Video from "../components/Video";
import Footer from "../components/Footer";
import Brands from "../components/Brands";
import Tools from "../components/Tools";
import HomeBanners from "../components/HomeBanners";
import Data from "../components/Data.jsx";
import MachineLearning from "../components/MachineLearning";
import BookDemo from "../components/BookDemo";
import Subscribe from "../components/Subscribe";

export default function Home() {
	const mainContainerId = "main-content";
	const pageSkipToContent = (
		<SkipToContent href={`#${mainContainerId}`}>Skip to content</SkipToContent>
	);
	return (
		<Page
			header={<Header />}
			sidebar={<SideBar />}
			isManagedSidebar
			skipToContent={pageSkipToContent}
			mainContainerId={mainContainerId}
			style={{ backgroundColor: "#373A40" }}
		>
			<HomeBanners />
			{/* <Data /> */}
			<Subscribe />
			<MachineLearning />
			<Blog />
			<Lab />
			<BookDemo />
			<Tools />
			<Video />
			<Brands />
			<Footer />
		</Page>
	);
}
