import React from "react";
import {
	Bullseye,
	Stack,
	StackItem,
	Title,
	Button,
	Text,
	TextInput,
	InputGroup,
	PageSection,
} from "@patternfly/react-core";

const Subscribe = () => {
	const redirectToRegister = () => {
		window.open("https://e2e.llmops.au/jupyter/basics/");
	};
	return (
		<PageSection
			style={{
				backgroundColor: "#003566",
				padding: "48px",
			}}
			className="subscribe-bg"
		>
			<Bullseye>
				<Stack hasGutter>
					<StackItem>
						<Title headingLevel="h1" size="2xl">
							Discover the power of our platform.
						</Title>
					</StackItem>
					<StackItem>
						<Text component="p">
							Stay up to date with our tender news, tips and blog posts.
						</Text>
					</StackItem>
					<StackItem>
						<Bullseye>
							<InputGroup>
								<TextInput
									id="demo-input"
									name="demo-input"
									type="text"
									aria-label="Demo input"
									placeholder="Enter your email"
								/>
								<Button variant="primary" onClick={redirectToRegister}>
									Subscribe
								</Button>
							</InputGroup>
						</Bullseye>
					</StackItem>
				</Stack>
			</Bullseye>
		</PageSection>
	);
};

export default Subscribe;
